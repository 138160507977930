import React, { useState, useEffect } from 'react';


function App() {

  // Kasutame useState konksu, et hoida saadud andmeid
  const [nimed, setNimed] = useState([]);
  // useEffect konks, et teha päring ainult siis, kui komponent on loodud
  useEffect(() => {
    fetch('https://reactphp.treeneritasu.ee/?ac=nimed')
      .then(response => response.json()) // Parseerime vastuse JSON-iks
      .then(data => {
        setNimed(data); // Seame saadud andmed nimede olekuks
        //console.info(data);
      })
      .catch(error => {
        //console.error('Error fetching data:', error); // Kui tekib viga, logime selle
      });
  }, []); // Tühjad sõltuvused, see tähendab, et see jooksutatakse ainult üks kord (komponendi loomisel)


  const [nimi, setNimi] = useState(null);
  useEffect(() => {
    const fetchRandomName = () => {
      fetch('https://reactphp.treeneritasu.ee/?ac=nimiRand')
        .then(response => response.json())
        .then(data => {
          setNimi(data);
        })
        .catch(error => {
          console.error('Viga andmete laadimisel:', error);
        });
    };
    // Käivitame funktsiooni iga sekundi järel
    const timer = setInterval(fetchRandomName, 1000);
    return () => clearInterval(timer);
  }, []); 

  const [history, setHistory] = useState([]);
  useEffect(() => {
    fetch('https://reactphp.treeneritasu.ee/?ac=history')
      .then(response => response.json()) 
      .then(data => {
        setHistory(data); 
        //console.info(data);
      })
      .catch(error => {
        //console.error('Error fetching data:', error); // Kui tekib viga, logime selle
      });
  }, []);
  
  return (
    <div className="App">
      <header className="App-header">
        Nimede loetelu:
        {nimed.map((inimene, index) => (
          <li key={index}>
            {inimene.eesnimi} {inimene.perenimi}
          </li>
        ))}
        <h1>Random nimed:</h1>
        {nimi ? (
          <p>{nimi.eesnimi} {nimi.perenimi}</p>
        ) : (
          <p>LOADING...</p> // Kuva "LOADING..." kuni andmed saabuvad
        )}

        History:
        {history.map((h, index) => (
          <li key={index}>
            <b>{h.kuupaev}:</b><br></br>
            {h.sisu}
          </li>
        ))}
      </header>
    </div>
  );
}

export default App;
